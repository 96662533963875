import { Box, Container, Typography, Skeleton, Grid, Alert } from "@mui/material";
import SwiperComponent from "components/common/swiper-component";
import { useSelector } from "react-redux";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

const AllProduct = () => {
  const { productsInfo, loading, error } = useSelector((state) => state.product);
  const hasProducts = productsInfo?.products?.length > 0;

  return (
    <>
      <Container className="mt-4">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Typography variant="h3" sx={{ color: "#000", fontWeight: 600 }}>
            All Gifts
          </Typography>
        </Box>

        {(loading || !hasProducts) && (
          <Grid container spacing={2}>
            {Array.from(new Array(4)).map((_, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Skeleton variant="rectangular" width="100%" height={200} />
                <Skeleton width="60%" />
                <Skeleton width="40%" />
              </Grid>
            ))}
          </Grid>
        )}

        {!loading && hasProducts && (
          <SwiperComponent slidesData={productsInfo?.products ?? []} />
        )}

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error?.message || "An error occurred while fetching the products."}
          </Alert>
        )}
      </Container>
    </>
  );
};

export default AllProduct;
